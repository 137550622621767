import { h } from 'preact';
import { Router, route } from 'preact-router';

import Header from './header';
import { config } from '../config';
import { account } from '../services/account.service';
import { storageHandler } from '../util/storage_handler';

// Code-splitting is automated for `routes` directory
import LoginSMS from '../routes/login-sms';
import AdminUserList from '../routes/admin-user-list';
import AdminUserDetails from '../routes/admin-user-details';
import AdminPrizeList from '../routes/admin-prize-list';
import AdminPrizeDetails from '../routes/admin-prize-details';
import AdminOrderList from '../routes/admin-order-list';
import AdminOrderDetails from '../routes/admin-order-details';
import AdminInquiryList from '../routes/admin-inquiry-list';
import AdminInquiryDetails from '../routes/admin-inquiry-details';
import AdminActionLogs from '../routes/admin-action-logs';


async function isAdminAuthenticated() {
	return typeof storageHandler.localStorageGet("isAdmin") !== "undefined" &&
		   typeof storageHandler.localStorageGet("token") !== "undefined";
}

const handleRoute = async e => {
	const isAdminAuthed = await isAdminAuthenticated();
	console.log(!isAdminAuthed)
	if (!isAdminAuthed) {
		route(`/admin/login`, true);
	} else if(!e.url.includes("/user") && 
		!e.url.includes("/prize") && 
		!e.url.includes("/order") && 
		!e.url.includes("/inquiry") && 
		!e.url.includes("/action-logs")){
		route(`/admin/user`, true);
	}
};

const App = () => (
	<div id="app">
		<Header />
		<Router onChange={handleRoute}>		
			<LoginSMS path="/admin/login" />	
			<AdminUserList path="/admin/user" />
			<AdminUserDetails path="/admin/user/:id" />
			<AdminPrizeList path="/admin/prize" />
			<AdminPrizeDetails path="/admin/prize/:id" /> 
			<AdminOrderList path="/admin/order" /> 
			<AdminOrderDetails path="/admin/order/:id" /> 
			<AdminInquiryList path="/admin/inquiry" /> 
			<AdminInquiryDetails path="/admin/inquiry/:id" /> 
			<AdminActionLogs path="/admin/action-logs" /> 

		</Router>
	</div>
)

export default App;
