import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';
import { storageHandler } from '../../util/storage_handler';
import { useEffect, useState } from 'preact/hooks';
import Menu from '../../components/menu';


const Header = () => {
	const [isAdmin, setIsAdmin] = useState(false);
	useEffect(()=>{
		if(!storageHandler.localStorageGet("lang")){
			storageHandler.localStorageSet("lang", "ja");
		}
		if(storageHandler.localStorageGet("isAdmin")){
			setIsAdmin(true);
		}
	}, []);
	return (
		<>
			<header class={style.header}>
				<Menu />
			</header>
			<div class={style["invert-curve"]} id="invert-curve"></div>
		</>
	)
};

export default Header;
