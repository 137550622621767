import { h } from 'preact';
import style from './style.css';


const Menu = (props) => {

	// function showDropdown(query){
	// 	document.querySelector("." + query).classList.toggle("active");
	// 	document.querySelector("." + query).style.display = document.querySelector("." + query).style.display === "none"? "block" : "none";
	// }

    function openNav() {
        const mq = window.matchMedia( "(max-width: 570px)" );
        document.getElementById("mySidepanel").style.width = mq.matches? "100%" : "320px";
        document.getElementById("invert-curve").style.zIndex = "0";
    }
      
    function closeNav() {
        document.getElementById("mySidepanel").style.width = "0";
        document.getElementById("invert-curve").style.zIndex = "100";
    }
	return (
            <>
                <button class={style["openbtn"]} onClick={openNav}><i class="fa fa-bars" /></button>  
                <div id="mySidepanel" class={style["sidepanel"]}>
                    <br/>
                    <a href="javascript:void(0)" class={style["closebtn"]} onClick={closeNav}>×</a>
                    <a href="/admin/user" class={style["linkPages"]}>User</a>
                    <a href="/admin/prize" class={style["linkPages"]}>Prize</a>
                    <a href="/admin/order" class={style["linkPages"]}>Orders</a>
                    <a href="/admin/inquiry" class={style["linkPages"]}>Inquiries</a>
                    <a href="/admin/action-logs" class={style["linkPages"]}>Action Logs</a>
                </div>
            </>
	);
};

export default Menu;